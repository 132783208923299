form.checkout-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  button {
    transition: 200ms ease all;
    &:hover {
      opacity: 0.67;
    }
  }
  .icon-contain {
    display: flex;
    gap: 5px;
    align-items: center;
    p {
      margin: 0;
      cursor: pointer;
    }
    cursor: pointer;
    &:hover {
      opacity: 0.67;
    }
  }
  .c-row {
    width: 100%;
    .conekta-input {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .bottom-inputs {
        display: flex;
        gap: 5px;
        .other-inputs {
          display: flex;
          gap: 5px;
        }
      }
    }
    input {
      width: 100%;
    }

    h4 {
      font-size: 1.4rem;

      span {
        color: #00e2f7;
        cursor: pointer;
      }
    }

    input {
      padding: 12px;
      box-shadow: none;
      text-shadow: none;
      border-radius: 5px;
      border: 2px solid #d3d3d3;
      background-color: #f8f8f8;
      font-size: 1.4rem;
    }
    select {
      width: 100%;
      padding: 8px;
      border-radius: 8px;
    }
    .StripeElement {
      border: 2px solid #d3d3d3;
      background-color: #f8f8f8;
      padding: 12px;
      border-radius: 5px;
    }
  }
  .cupon-container {
    display: flex;
    position: relative;
    .loader-ctn {
      width: 76px;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    input {
      flex: 1;
      outline: none;
    }
    button {
      padding-inline: 10px;
      border-radius: 5px;
      cursor: pointer;
    }
    .check-ctn {
      width: 76px;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        color: rgb(36, 205, 36);
        font-size: 2.6rem;
      }
    }
    .error {
      position: absolute;
      color: red;
      font-weight: 600;
      font-size: 14px;
      bottom: -24px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
    }
  }
  .button-contain {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    .btn-oxxo {
      padding-block: 10px;
      font-weight: 600;
      border-radius: 100px;
    }
    .paypal-button-tagline {
      .paypal-button-text {
        color: red;
      }
    }
  }
}
