@import "src/styles/mixin.scss";

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: #292929;
  box-shadow: 0 5px 4px rgb(0 0 0 / 28%);
  height: 60px;

  .header {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 70px;
    }

    h2 {
      font-size: 2.4rem;
      font-family: "Bear-font";
      color: #ffffff;
      margin: 0;
    }

    div.burger-btn {
      display: none;
    }
  }
  ul {
    display: flex;
    list-style: none;
    gap: 10px;
    padding: 0;
    margin: 0;
   
    li {
      cursor: pointer;
      color: #ffffff;
      border-radius: 50px;
      background-color: #404040;
      padding: 4px 8px;
      transition: all 0.1s ease;
      font-family: "Poppins";
      font-size: 1.4rem;
      font-weight: bold;
      gap: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: #08e1f7;
        color: #000000;
      }
    }
  }

  @include respond-to(md) {

    .header {
      div.burger-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        border-radius: 50px;
        background-color: #404040;

        svg {
          color: #ffffff;
          width: 20px;
          height: 20px;
        }
      }
    }

    ul {
      li {
        padding: 8px;
        svg {
          width: 1.6rem;
          height: 1.6rem!important;
        }
        span {
          display: none;
        }
      }
    }
  }

  @include respond-to(sm) {
    .header {
      h2 {
        display: none;
      }
    }
  }
}
