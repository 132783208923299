form.sign-up-form {
  display: flex;

  .c-row {
    position: relative;
    .special-label {
      display: none;
    }
    .code{
      position: absolute;
      font-size: 18px;
      color: white;
      margin: 0;
      left: 50px;
      top: 48%;
      transform: translateY(-50%);
    }
    .dial-container {
      position: absolute;
      width: auto;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1000;
      input {
        display: none;
        background-color: transparent;
        border: none;
        font-size: 1.8rem;
        padding: 0;
        padding-left: 55px;
        color: #ffffff;
        margin: 0 !important;
        width: 100px;
        &:focus {
          box-shadow: none;
        }
      }
    }
    input.phone {
      padding-left: 100px;
    }
  }
}
