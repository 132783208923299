@import "src/styles/mixin.scss";

.my-account-main-container {
  display: flex;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  .card-status {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #323232;
    margin-left: 20px;
  }
  .default {
    background-color: #06e1f7;
  }
  .no-default {
    cursor: pointer;
    &:hover {
      background-color: #06e1f7;
      opacity: 0.67;
    }
  }
  .new-card-box {
    display: flex;
    width: 100%;
    right: 0px;
    opacity: 0;
    transition: 0.3s ease all;
  }
  .active-card {
    opacity: 1;
  }
  .general {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-grow: 1;
    max-width: 400px;
    margin-bottom: 10px;
    .cancel {
      color: white;
      background-color: #ff2b2b;
      padding-inline: 45px;
      padding-block: 10px;
      border-radius: 100px;
      font-weight: 600;
      &:hover {
        opacity: 0.8;
      }
    }
    .user-profile-pic {
      width: 200px;
      height: 200px;
      max-width: 200px;
      min-width: 200px;
      border-radius: 100%;
      aspect-ratio: 1/1;
      background-color: #323232;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h2 {
      font-size: 2.8rem;
      font-weight: bold;
    }

    .user-info-container {
      background-color: #ffffff;
      border-radius: 8px;
      padding: 16px 20px;
      box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.39);
      display: flex;
      flex-direction: column;
      gap: 10px;

      .c-row {
        display: grid;
        grid-template-columns: 1fr 2fr;
      }
    }
  }

  .purchase {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 15px;
    .actives-ftp-container {
      width: 100%;

      h2 {
        font-size: 3.2rem;
        font-weight: bold;
        text-align: center;
        position: relative;
        svg {
          position: absolute;
          right: 0px;
          border-radius: 50%;
          color: #323232;
          transition: 0.3s ease all;
          cursor: pointer;
          &:hover {
            opacity: 0.67;
          }
        }
        .active {
          rotate: 45deg;
        }
      }
      .no-items-container {
        background-color: #f8d7da;
        border: 1px solid #f5c2c7;
        padding: 16px;
        border-radius: 5px;
        p {
          color: #842029;
          margin: 0;
          font-size: 1.4rem;
        }
      }
    }
    .actives-ftp-container.cards {
      background-color: #ffffff;
      box-shadow: 0px 6px 10px 0px #00000026;
      border-radius: 6px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      h2 {
        font-size: 2.4rem;
        font-weight: bold;
        text-align: left;
      }
      .card {
        border: none;
        border-bottom: 1px solid #e2e2e2;
        border-radius: 0;
        padding-inline: 10px;
        padding-bottom: 10px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        align-items: center;
        .default-card{
          color: #0096A5;
        }
        p {
          margin: 0;
        }
        .circle {
          background-color: #e2e2e2;
          width: 45px;
          height: 45px;
          border-radius: 50px;
          display: flex;
          img {
            margin: auto;
          }
        }
        svg {
          color: #ff2d2c;
          cursor: pointer;
        }
      }
      .new {
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        color: #0096a5;
        text-align: right;
      }
    }

    .last-purchased {
      display: flex;
      flex-direction: column;
      gap: 10px;
      h2 {
        font-size: 2.8rem;
        font-weight: bold;
        text-align: center;
      }
    }

    table.table {
      border-radius: 6px;
      overflow: hidden;
      box-shadow: 0 3px 8px rgb(0 0 0 / 14%);
      thead {
        background-color: #323232;
        th {
          background: none;

          color: #06e1f7;
          padding: 10px;
        }
      }
      svg {
        cursor: pointer;
        margin-left: 20px;
        &:hover {
          opacity: 0.67;
        }
      }
      tbody {
        background-color: #e2e2e2;

        tr {
          td {
            padding: 10px;
            background: none;
            vertical-align: middle;
            img {
              width: 35px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .no-card {
      tbody {
        background-color: #292929 !important;
        .text-center {
          color: #06e1f7;
        }
      }
    }
  }

  @include respond-to(md) {
    .purchase {
      width: 100%;

      table.table {
        width: 100%;
        margin-bottom: 10px;

        font-size: 1.4rem;

        thead {
          th {
            padding: 5px;
            word-break: break-all;
          }
        }
        tbody {
          tr {
            td {
              padding: 5px;
              word-break: break-all;
            }
          }
        }
        h2 {
          font-size: 1.4rem;
        }
      }
    }
  }
}
