.space-available-card {
  background-color: #ffffff;
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 3px 8px rgb(0 0 0 / 14%);
  display: flex;
  flex-direction: column;
  gap: 10px;

  h2.title {
    color: #474747;
    font-size: 2.2rem;

    span {
      color: #0096a5;
    }
  }

  h3 {
    color: #afafaf;
    span {
      color: #0096a5;
      font-weight: bolder;
    }
  }

  .progress-bar-container {
    background-color: #cff3f6;
    padding: 8px;
    border-radius: 25px;

    .progress-bar {
      height: 16px;
      background-color: #08e1f7;
      border-radius: 25px;
    }
  }
  .extra-gb {
    color: #0096a5;
    font-size: 1.4rem;
    margin: 0;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      opacity: 0.67;
    }
  }
  .bottom-options {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: flex-end;
    align-items: center;
    p {
      margin: 0;
    }
    .button {
      color: #0096a5;
      font-weight: 600;
      font-size: 1.4rem;
      cursor: pointer;
      &:hover {
        opacity: 0.67;
      }
    }
  }
}
