.plan-card-main-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: linear-gradient(45deg, black, #505050);
  flex: 1;
  max-width: 350px;
  min-width: 300px;
  box-shadow: -10px -10px 0 #08e1f7;
  transition: all 0.1s ease;
  user-select: none;
  border-radius: 4px;
  padding: 30px;
  gap: 20px;
  color: #ffffff;
  height: 750px;
  position: relative;
  .announce{
    top: -20px;
    right: 25px;
    position: absolute;
    background-color: #08e1f7;
    color: #fff;
    border-radius: 100px;
    font-weight: 600;
    font-size: 1.7rem;
    padding-block: 4px;
    padding-inline: 12px;
    margin: 0;
  }
  .paypal-data{
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: center;
      width: 100%;
      .text{
        font-size: 2rem;
        font-weight: 500;
        font-style: italic;
        color: #7AE1F6;
        margin: 0;
      }
      img{
        width: 100px;
      }
  }
  &:hover {
    box-shadow: -13px -13px 0 #08e1f7;
  }

  .c-row {
    width: 100%;
    position: relative;
    .line {
      position: absolute;
      width: 145px;
      height: 4px;
      border-radius: 10px;
      background-color: red;
      bottom: 15px;
      left: 72px;
      rotate: 170deg;
    }
    h2 {
      font-size: 3.4rem;
      font-weight: 700;
      text-align: center;
    }

    h3 {
      font-size: 24px;
      font-weight: 700;
      color: #08e1f7;
      font-family: "Bear-font";
      text-align: center;
    }

    p {
      margin: 0;
      font-size: 1.4rem;
      font-weight: 500;
      svg {
        color: #25d366;
        font-size: 1.6rem;
        margin-right: 10px;
      }
    }
  }
  .button-contain{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: auto;
    width: 100%;
    .paypal-size{
      height: 60px;
    }
    .space{
      height: 43px;
    }
  }
  button {
    border-radius: 22px;
    padding: 8px;
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
    background: linear-gradient(11deg, #00e6c1, #08e1f7);
    color: #1b1b1b;
    border: none;
    width: 100%;
    text-align: center;
    &:hover{
      opacity: .6;
    }
  }
  .silver-bg {
    background: linear-gradient(11deg, #eeeeee, #d5d5d5);
  }
}
.resp-plan{
  @media(max-width: 991px){
    order: 2;
  }
}
.plan-white-card{
  color: #1b1b1b;
  background: #FFFFFF;
  h2{
    color:#08e1f7;
  }
  .c-row{
    h3{
      color: #1b1b1b;
    }
  }
  .paypal-data{
    .text{
      color: #1b1b1b;
    }
  }
}