.storage{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .storage-card {
        background-color: #ffffff;
        width: 60%;
        height: 40%;
        padding: 30px;
        border-radius: 8px;
        box-shadow: 0 3px 8px rgb(0 0 0 / 14%);
        display: flex;
        flex-direction: column;
        gap: 10px;
      
        h2.title {
          color: #474747;
          font-size: 2.2rem;
      
          span {
            color: #0096a5;
          }
        }
      
        h3 {
          color: #afafaf;
          span {
            color: #0096a5;
            font-weight: bolder;
          }
        }

        .reserved-space-text {
          color: #e90b0b;
        }
      
        .progress-bar-container {
          background-color: #cff3f6;
          padding: 1px;
          border-radius: 5px;
          display: flex;
      
          .progress-bar {
            height: 16px;
            background-color: #08e1f7;
            border-radius: 3px;
          }
          .reserved-space-bar {
            width: 5%;
            margin-right: 1px;
            background-color: #e90b0b;
          }
        }
        .extra-gb{
          color: #0096a5;
          font-size: 1.4rem;
          margin:0;
          text-decoration: underline;
          cursor: pointer;
          &:hover{
            opacity: .67;
          }
        }
      }
      
}
