.modal-container {
    width: 100vh;

    .bottom {
        .table-contain {
            table {
                color: white;
                border-spacing: 10px;
                border-color: white;
                border-collapse: collapse;
                width: 100%;

                thead {
                    tr {
                        border-bottom: 1px solid white;
                        th {
                            padding: 10px;
                        }
                    }
                    background-color: #2a2a2a;
                    z-index: 1;
                }

                tbody {
                    tr {
                        border-bottom: 1px solid white;

                        td {
                            padding: 10px;
                        }
                    }
                }

                tfoot {
                    tr {
                        th {
                            div {
                                color: black
                            }
                        }
                    }
                }
            }

            table thead,
            table tfoot {
                position: sticky;
            }

            table thead {
                inset-block-start: 0;
            }

            table tfoot {
                inset-block-end: 0;
            }
        }
    }
}