.modal-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  button {
    padding-inline: 50px;
    padding-block: 8px;
    font-weight: 600;
    font-size: 16px;
    border-radius: 100px;

    &:hover {
      opacity: 0.8;
    }
  }

  p {
    margin: 0;
  }

  img {
    width: 112px;
    height: 50px;
    margin-block: 10px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 25px;
    padding-block: 10px;
    background-color: #1a1a1a;
    border-radius: 10px 10px 0px 0px;

    .title {
      color: white;
      font-weight: 600;
      font-size: 20px;
    }

    .icon {
      color: white;
      font-size: 24px;
      cursor: pointer;
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 0px 0px 10px 10px;
    padding-block: 20px;
    padding-inline: 25px;
    background-color: #2a2a2a;

    .content {
      color: white;
    }

    .button-container {
      display: flex;
      gap: 20px;
      justify-content: space-between;

      .btn-cancel {
        background-color: transparent;
        color: #ff2d2c;
      }

      .btn-retry {
        background-color: #ff2d2c;
        color: white;
      }

      .btn-option-1 {
        background-color: transparent;
        color: #39d834;
      }

      .btn-option-2 {
        background-color: #39d834;
        color: white;
      }

      .btn-option-3 {
        background-color: #febc2e;
        color: white;
      }

      .btn-option-4 {
        background: linear-gradient(45deg, #04e4cc, #00e1ee);
        color: white;
      }

      .btn-option-5 {
        background: transparent;
        color: #09e1f7;
      }
    }

    .to-left {
      justify-content: flex-end;
    }

    .reference {
      color: white;
      font-size: 12px;
    }

    .pay-reference {
      color: white;
      font-size: 18px;
    }

    .button-container-2 {
      display: flex;
      justify-content: center;

      .btn-success {
        width: fit-content;
        background: linear-gradient(45deg, #04e4cc, #00e1ee);
      }

      .btn-option-5 {
        background: transparent;
        color: #09e1f7;
      }

      .btn-cancel {
        background-color: #ff2d2c;
        color: white;
      }
    }
  }

  .center {
    text-align: center;
    align-items: center;
    gap: 7px;
  }
}

.modal-content {
  border-radius: 25px;
}

.error-modal {
  box-shadow: -6px -6px 0px 2px #ff2d2c;
}

.success-modal {
  box-shadow: -6px -6px 0px 2px #09e1f7;
}

.option-modal {
  box-shadow: -6px -6px 0px 2px #39d834;
}

.oxxo-modal {
  box-shadow: -6px -6px 0px 2px #febc2e;
}

.card-input {
  border: 2px solid #d3d3d3;
  background-color: #f8f8f8;
  padding: 12px;
  border-radius: 5px;
}

.modal-addusers {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 30px;
  gap: 20px;
  // background-color: red;

  .c-row {
    position: relative;
    width: 100%;
    justify-content: center !important;
    label {
      font-weight: 500;
    }
    .formik {
      color: red;
      padding-left: 4px;
      position: absolute;
      bottom: -22px;
      left: 0px;
    }

    input {
      border-radius: 100px;
      padding: 5px 25px;
      width: 100%;
      // border: none;
      background: transparent;
    }
  }

  .c-row2 {
    position: relative;
    margin-bottom: 30px;
    width: 100% !important;

    .special-label {
      display: none;
    }

    .code {
      position: absolute;
      font-size: 18px;
      color: black;
      margin: 0;
      left: 50px;
      top: 48%;
      transform: translateY(-50%);
    }

    .dial-container {
      position: absolute;
      width: auto;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1000;

      input {
        display: none;
        background-color: transparent;
        border: none;
        font-size: 1.8rem;
        // padding: 0;
        padding-left: 55px;
        color: black;
        margin: 0 !important;
        // width: 100px;

        &:focus {
          box-shadow: none;
        }
      }
    }

    input.phone {
      border-radius: 100px !important;
      padding: 5px 90px !important;
      width: 100% !important;
    }
  }

  .btn-option-4 {
    border-radius: 100px;
    padding: 5px 25px;
    background: linear-gradient(45deg, #04e4cc, #00e1ee);
    color: white;
    font-weight: bold;
  }

  .btn-cancel {
    border-radius: 100px;
    padding: 5px 25px;
    background-color: #ff2d2c;
    font-weight: bold;
    color: white;
  }

  .icon {
    color: black;
    font-size: 24px;
    cursor: pointer;
    align-self: flex-end;
  }

  h2 {
    // font-family: "Bear-font";
    color: black;
    font-size: 3.2rem;
    text-align: center;
    margin-bottom: 20px;
  }
}

select {
  background-color: transparent;
  // font-size: 1.8rem;
  // padding: 0;
  color: black;
  border-radius: 100px !important;
  padding: 5px 20px !important;
  width: 100% !important;
  border-width: 2px;
  // border-color: black;
  // width: 100px;

  &:focus {
    box-shadow: none;
  }
}

.c-row-price {
  display: flex;
  flex-direction: row !important;
  // position: relative;
  // margin-bottom: 30px;
  width: 100%;
  justify-content: center !important;

  .formik {
    color: red;
    padding-left: 4px;
  }

  input {
    border-radius: 100px;
    padding: 5px 25px;
    width: 80%;
    // border: none;
    background: transparent;
  }

  select {
    width: 20% !important;
    padding: 5px 10px !important;
  }
}

.modal-UHREMIX {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #080a0f;
  padding: 20px 0px;
  .top-side {
    display: flex;
    width: 100%;
    justify-content: end;
    padding: 0px 30px;
    .icon {
      color: white;
      font-size: 30px;
      cursor: pointer;
    }
  }
  .container {
    display: flex;
    width: 100%;
    height: 100%;
    .left {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      gap: 20px;
      color: white;
      background-image: url(../../assets/images/backgroundModal.png);
      background-size: cover;
      padding: 0px 30px;
      .icon {
        font-size: 70px;
      }
      .div-text {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;
        h3 {
          font-size: 24px;
          font-weight: 600;
        }
        .blue {
          color: #2b65c1;
        }
      }
      .div-bottom {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: end;
        width: 100%;
        .blue {
          color: #4dd8f4;
        }
      }
      h2 {
        font-weight: 700;
        font-size: 3vw;
      }
      p {
        font-size: 1.1vw;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      width: 75%;
      height: 100%;
      color: white;
      padding: 0px 30px;
      .checkout-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 50vh;
        padding: 0px 30px;
        justify-content: center;
        border: 1px solid #2b65c1;
        border-radius: 10px;
        margin-top: 20px;
        .color-blue {
          color: #2b65c1;
        }
        p {
          color: #2b65c1;
        }
      }
      .top-side {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-top: 30px;
        gap: 20px;
        h3 {
          font-size: 24px;
          font-weight: 600;
          span {
            font-size: 44px;
            font-weight: 800;
          }
        }
        .blue {
          color: #4dd8f4;
        }
      }
      .div-bottom {
        display: flex;
        width: 100%;
        padding-top: 20px;
        justify-content: center;
        .btn {
          width: 100%;
          font-weight: 600;
          padding: 8px 10px;
          background-color: #2b65c1;
          color: white;
        }
      }
    }
  }
}

@media (max-width: 780px) {
  .modal-UHREMIX {
    .container {
      flex-direction: column;
      .left {
        align-items: center;
        h2{
          font-size: 24px;
          text-align: center;
        }
        p{
          text-align: center;
          font-size: 18px;
          ul{
            margin-top: 10px;
          }
        }
        
        .div-bottom{
          display: none;
        }
      }
      .right {
        width: 100%;
      }
    }
  }
}
