.coupons-contain {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    .input {
      border-radius: 100px;
      padding: 5px 25px;
      // border: none;
      background: transparent;
    }
    .btn-addCoupon {
      color: white;
      background: #09e1f7;
      padding: 4px 20px;
      border-radius: 100px;
    }
    .btn-addCoupon:hover {
      background-color: #00abbe;
    }
  }
  p {
    margin: 0;
  }
  .admin-table {
    table tbody tr td button {
      margin-right: 10px;
    }
  }
}
