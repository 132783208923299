@import "~bootstrap/scss/bootstrap";
@import "./mixin.scss";
@font-face {
  font-family: "Bear Font2";
  src: url("../assets/fonts/Arkhip_font.otf");
}
@font-face {
  font-family: "Bear-font";
  src: url("../assets/fonts/Arkhip_font.ttf");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

a {
  text-decoration: none;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Poppins", sans-serif;
}

label,
p,
a,
b,
button,
.btn,
ul,
li,
div,
form {
  font-size: 1.6rem;
}

button {
  border: none;
}

button.primary-pill {
  border-radius: 25px;
  font-weight: 700;
  padding: 10px;

  &:hover {
    color: #000;

    box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
  }
}

.linear-bg {
  background: linear-gradient(11deg, #00e6c1, #08e1f7);
}
.silver-bg {
  background: linear-gradient(11deg, #eeeeee, #d5d5d5);
}
.paypal-buttons {
  .buttons-container{
    .paypal-button-container{
      .paypal-button-tagline{
        .paypal-button-text{
          color: white;
        }
      }
    }
  }
}
.error-formik{
  color: red;
  font-weight: 600;
  position: absolute;
  bottom: -20px;
  font-size: 12px;
  left: 50%;
  transform: translateX(-50%);
}
.search-input{
  display: flex;
  position: relative;
  background-color: white;
  width: 350px;
  padding-block: 10px;
  padding-inline: 15px;
  border-radius: 8px;
  input{
    background: transparent;
    border: none;
    outline: none;
    font-size: 1.2rem;
    font-weight: 600;
    width: 100%;
  }
  svg{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: #6FDCF4;
    pointer-events: none;
  }
}
.select-input{
  width: 200px;
  select{
    width: 100%;
    background-color: white;
    padding-block: 10px;
    padding-inline: 15px;
    border-radius: 8px;
    border: none;
    font-size: 1.4rem;
    font-weight: 600;
    outline: none;
  }
}