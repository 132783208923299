.file-loader-contain {
  display: flex;
  flex-direction: column;
  position: fixed;
  border-radius: 20px;
  width: 450px;
  bottom: 20px;
  right: 20px;
  padding: 0px !important;
  box-shadow: 0 1px 20px 5px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  overflow: hidden;
  z-index: 100;
  p {
    margin: 0;
  }
  .header {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding-block: 10px;
    padding-inline: 24px;
    align-items: center;
    .title {
      font-weight: 600;
      font-size: 2rem;
    }
    .subtitle {
      font-weight: 300;
      font-size: 1.2rem;
    }
  }
  .files-list {
    display: flex;
    flex-direction: column;
    padding-block: 12px;
    padding-inline: 24px;
    gap: 10px;
    .file-contain {
      display: flex;
      justify-content: space-between;
      .left-side {
        display: flex;
        align-items: center;
        gap: 15px;
        svg {
          font-size: 2.6rem;
        }
        .title-contain {
          display: flex;
          flex-direction: column;
        }
      }
      .right-side {
        display: flex;
        align-items: center;
        gap: 30px;
        button {
          background-color: transparent;
          font-weight: 600;
        }
      }
    }
  }
  @media (max-width: 600px) {
    width: 90%;
    right: 5%;
  }
}
