.auth-main-container {
  background-color: #2c2828;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: none!important;
  width: 100%;
  overflow-y: auto;
  // overflow-y: scroll;

  img.logo {
    max-width: 350px;
    width: 100%;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 600px;
    padding: 20px;

    h2 {
      font-family: "Bear-font";
      color: #ffffff;
      font-size: 3.2rem;
      text-align: center;
    }

    .c-row {
      width: 100%;
      position: relative;
      > input {
        width: 100%;
        padding: 8px 16px;
        border-radius: 100px;
        font-size: 1.8rem;
        background: rgba(0, 0, 0, 0.3);
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
        border: 1px solid rgba(0, 0, 0, 0.3);
        outline: none;
        box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.2), 0 1px 1px rgba(255, 255, 255, 0.2);
        transition: box-shadow 0.5s ease;
        color: #ffffff;

        &:focus {
          box-shadow: inset 0 -5px 45px rgba(100, 100, 100, 0.4), 0 1px 1px rgba(255, 255, 255, 0.2);
        }
      }

      a {
        text-decoration: none;
        color: #ffffff;
        font-size: 1.4rem;
      }
    }
    button {
      border-radius: 100px;
      background-color: #06e1f7;
      color: #000000;
      font-weight: 700;
      font-size: 1.8rem;
      padding: 10px;
    }
  }

  .arrow {
    stroke: #ffffff;
    width: 1.8rem;
    height: 1.8rem;
    transform: rotate(90deg);
  }
}
