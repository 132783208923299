@import "src/styles/mixin.scss";

.home-main-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .header-contain {
    display: flex;
    justify-content: space-between;
  }
  h2 {
    font-size: 2.8rem;
  }
  .btn-back {
    button {
      display: flex;
      gap: 10px;
      padding-block: 5px;
      padding-inline: 20px;
      border-radius: 100px;
      background: linear-gradient(#2c2c2c, #3d3d3d);
      color: #cbcbcb;
      font-weight: 600;
      align-items: center;
      &:hover {
        color: white;
        gap: 15px;
        transition: 0.3s ease all;
      }
    }
  }
  .folder-structure-container {
    display: flex;
    div {
      display: flex;
      align-items: center;
    }
    p {
      margin: 0px 3px 0px 3px;
      cursor: pointer;
      padding: 5px;
    }
    p:hover {
      background-color: #d1d1d1;
      border-radius: 10px;
    }
    .last-folder {
      cursor: default;
    }
  }
  .folders-navigation-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
    .header {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      border-bottom: 1px solid #1f75cc;
      color: #1f75cc;
      padding: 8px;

      div {
        font-size: 1.4rem;
        cursor: pointer;
      }
    }

    .folders-cards-container {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .folder-card {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        // padding: 8px;
        background-color: #ffffff;
        border-radius: 8px;
        cursor: pointer;
        box-shadow: 0 1px 4px #00000033;
        position: relative;
        .name-container {
          padding: 8px;
        }
        .download-button {
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          right: 20px;
          z-index: 4;
          &:hover {
            opacity: 0.6;
          }
        }
        &:hover {
          background-color: #00000013;
        }
        svg {
          width: 3rem;
          height: 3rem;
        }
        div {
          display: flex;
          align-items: center;
          gap: 15px;

          h3 {
            font-size: 1.5rem;
            font-weight: 400;
          }

          h4 {
            font-size: 1.2rem;
            font-weight: 400;
          }
        }
      }

      .video-card {
        grid-template-columns: auto 1fr auto;
        padding: 10px;
        align-items: center;
        .name-container {
          padding-inline: 30px;
          justify-content: center;
        }
      }
    }
  }

  @include respond-to(md) {
    .folders-navigation-container {
      .header {
        .modified-column {
          display: none;
        }
      }

      .folders-cards-container {
        .folder-card {
          .modified-column {
            display: none;
          }
        }
      }
    }
  }
  @media (max-width: 700px) {
    .header-contain {
      flex-direction: column;
      gap: 20px;
    }
    .search-input {
      width: 100%;
    }
  }
}

.pagination-container {
  position: sticky;
  bottom: 0;
}