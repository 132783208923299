.admin-contain {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .header {
        display: flex;
        gap: 20px;

        @keyframes loadRed {
            0% {
                background-color: red;
            }

            50% {
                background-color: rgb(246, 76, 76);
            }

            100% {
                background-color: red;
            }
        }

        @keyframes loadBorder {
            0% {
                border: 2px solid #ff0000;
            }

            50% {
                border: 2px solid rgb(246, 76, 76);
            }

            100% {
                border: 2px solid #ff0000;
            }
        }

        .progress-delete {
            display: flex;
            color: black;
            padding-block: 4px;
            border-radius: 100px;
            border-radius: 100px;
            margin-left: auto;
            width: 190px;
            display: flex;
            justify-content: center;
            position: relative;
            overflow: hidden;
            animation: loadBorder 1.5s infinite;

            p {
                text-shadow: 0 0 1px black;
            }

            .progress {
                height: 100%;
                width: 100%;
                position: absolute;
                border-radius: 100px;
                animation: loadRed 1.5s infinite;
                top: 0px;
                z-index: -1;
                transition: .2s ease all;
            }
        }

        .finish {
            animation: unset;
            border: 2px solid #ff0000;

            .progress {
                animation: unset;
                background-color: red;
            }
        }

        .input {
            border-radius: 100px;
            padding: 5px 25px;
            // border: none;
            background: transparent;
        }

        .btn-addUsers {
            color: white;
            background: #09e1f7;
            ;
            padding: 4px 20px;
            border-radius: 100px;
        }

        .btn-delete {
            color: white;
            background: #f71109;
            ;
            padding: 4px 20px;
            border-radius: 100px;
        }

        button:hover {
            opacity: .67;
        }
    }

    p {
        margin: 0;
    }

    .users-contain {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .user-contain {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 100px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.54);
            padding: 5px 20px;

            .data-contain {
                display: flex;
                flex-direction: column;
            }

            .btn-active {
                color: white;
                background: #09e1f7;
                ;
                padding: 4px 20px;
                border-radius: 100px;
            }

            &:hover {
                background-color: rgb(202, 202, 202)
            }
        }
    }
}

.admin-table {
    display: flex;
    flex-direction: column;

    .table-contain {
        width: 100%;
        max-height: 73vh;
        overflow-y: visible;
    }

    table thead,
    table tfoot {
        position: sticky;
    }

    table thead {
        inset-block-start: 0;
    }

    table tfoot {
        inset-block-end: 0;
    }

    table {
        border-spacing: 0;
        width: 100%;

        thead {
            z-index: 1;

            tr {
                background-color: #dee2e6;
                border-bottom: 1px solid white;
                position: sticky;
                top: 0;
                z-index: 2;

                th {
                    font-size: 1.4rem;
                    max-width: 250px;
                    padding: 10px;
                }
            }

            tr th:last-child {
                text-align: center;
            }

        }

        tbody {
            tr {
                background-color: #e8ecef;
                border-bottom: 1px solid white;

                td {
                    font-size: 1.2rem;
                    // max-width: 250px;
                    padding-block: .5rem;
                    padding: 10px;

                    button {
                        font-size: 1.2rem;
                        color: white;
                        background: #09e1f7;
                        padding: 4px 20px;
                        border-radius: 100px;

                        &:hover {
                            opacity: .67;
                        }
                    }

                    button.disable {
                        background: rgb(175, 174, 174);

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

                .wrap-td {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 5px;
                    justify-content: space-around;

                    button {
                        width: 160px;
                    }

                    svg {
                        font-size: 2.4rem;
                        cursor: pointer;
                        width: 160px;
                    }

                    .lock {
                        color: red;

                        &:hover {
                            color: rgba(160, 7, 7, 0.489);
                        }
                    }

                    .unlock {
                        color: black;

                        &:hover {
                            opacity: .5;
                        }
                    }

                    .dropbtn {
                        background-color: #09e1f7;
                        color: white;
                        width: 160px
                    }

                    .dropdown {
                        position: relative;
                        display: inline-block;
                    }

                    .dropdown-content.dropdown-up {
                        top: -232px;
                    }

                    .dropdown-content {
                        display: none;
                        position: absolute;
                        min-width: auto;
                        background-color: #dadee0;
                        box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.2);
                        z-index: 1;
                        gap: 15px;
                        text-align: center;
                        border-radius: 10px;
                        overflow: hidden;
                        right: -15px;
                        top: 26px;
                    }

                    .dropdown-content button {
                        color: black;
                        padding: 10px 16px;
                        text-decoration: none;
                        display: block;
                        cursor: pointer;
                        border-radius: 0%;
                        font-size: 1.2rem;
                        background-color: #dadee0;
                        min-width: auto;
                    }

                    .dropdown-content button:hover {
                        background-color: #d0d0d0;
                    }

                    .dropdown-content button:disabled {
                        cursor: not-allowed;
                        color: gray;
                    }

                    .dropdown-content button:disabled:hover {
                        background: none;
                    }

                    .dropdown:hover .dropdown-content {
                        display: block;
                    }

                    .dropdown:hover .dropbtn {
                        background-color: #09e1f7;
                    }

                    .dropdown-content button.icon-button {
                        padding: 9px 0px;
                    }
                }
            }

            @keyframes loadingAnimation {
                0% {
                    background-color: #d6d5d5;
                }

                50% {
                    background-color: #c2c1c1;
                }

                100% {
                    background-color: #d6d5d5;
                }
            }

            .tr-load {
                td {
                    animation: loadingAnimation 1.5s infinite;
                    border-block: 5px solid #e8ecef;
                    padding: 22px;
                    background: linear-gradient()
                }
            }
        }

        tfoot {
            width: 100%;
        }
    }
}