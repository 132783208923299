@import "src/styles/mixin.scss";

.main-layout-main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  background-color: #eeeeee;
  .content-container {
    flex: 1;
    display: flex;
    position: relative;
    overflow-y: scroll;

    > div {
      flex: 1;
      margin-left: auto;
      margin-right: auto;
      max-width: 1320px;
      padding: 24px;
    }
  }

  @include respond-to(md) {
    .content-container {
      > div {
        padding: 12px;
      }
    }
  }
  
}
